import React from "react";
import { DiscoveryTourTake, TryRandom } from "./HeroBanners";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const LibraryCtaDiv = styled.div`

`;


/**
 * Give users call to action (CTA), a place to go at the end of the library.
 */
const LibraryCta = ({ illustrationHeight }) => {
  return (
    <Grid container
          spacing={3}
          direction="row"
          wrap="wrap"
          alignItems="flex-start"
          justify="space-evenly">
      <Grid item xs={12} sm={6} md={6}>

        <DiscoveryTourTake illustrationHeight={illustrationHeight} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TryRandom illustrationHeight={illustrationHeight} />
      </Grid>
    </Grid>
  );
};
LibraryCta.propTypes = {
  illustrationHeight: PropTypes.string.isRequired,
};

export default LibraryCta;
