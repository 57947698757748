import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import BadgeItem from "./BadgeItem";

const InnerDiv = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
`;

const Badges = () => (
  <>
    <Typography component="h2" variant="h6" gutterBottom>My Badges</Typography>

    <Grid container
          spacing={0}
          direction="row"
          wrap="wrap"
          alignItems="flex-start"
          justify="space-evenly">
      <Grid item xs={12} sm={6} md={6}>
        <InnerDiv>
          <BadgeItem caption="Yearling" outline="bronze" />
          <BadgeItem caption="I occasionally play video games" outline="silver" />
        </InnerDiv>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <InnerDiv>
          <BadgeItem caption="First Discovery Tour" outline="gold" />
          <BadgeItem caption="Connected Steam account" />
        </InnerDiv>
      </Grid>
    </Grid>
  </>
);

export default Badges;