import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import RatingCoins from "../element/graphical/RatingCoins";
import { lessOrEqualThan } from "../util/mediaQuery";

const LastPlayedInfoDiv = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  ${lessOrEqualThan.sm`margin-top: 0.5rem;`};
`;

const LastPlayedInfo = ({ game }) => {
  let ratingText = null;
  if (game.userRating === 0) {
    ratingText = "NO RATING";
  } else {
    ratingText = "Rating";
  }

  return (
    <LastPlayedInfoDiv>
      <Typography variant="body2" color="inherit">{ratingText}</Typography>
      <RatingCoins userRating={game.userRating} />
      <Typography variant="body2" color="inherit">{game.playedHours} hours played</Typography>
    </LastPlayedInfoDiv>
  );
};
LastPlayedInfo.propTypes = {
  game: PropTypes.shape({
    header: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
    userRating: PropTypes.number.isRequired,
    playedHours: PropTypes.number.isRequired,
  }).isRequired,
};
export default LastPlayedInfo;
