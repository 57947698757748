import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import Summary from "../backlog-challenge/Summary";
import background from "./visual/backlog-challenge-OberionIO.min.svg";

const BackgroundDiv = styled.div`
  width: 100%;
  height: ${props => props.illustrationHeight};
  background-image: url(${background});
  background-size: cover;
  
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
`;

const BacklogChallengeBanner = ({ backlogChallenge, illustrationHeight }) => (
  <BackgroundDiv illustrationHeight={illustrationHeight}>
    <Summary backlogChallenge={backlogChallenge} progressBarWidth="9rem" />
  </BackgroundDiv>
);

BacklogChallengeBanner.propTypes = {
  backlogChallenge: PropTypes.shape({
    year: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
    goal: PropTypes.number.isRequired,
  }).isRequired,
  illustrationHeight: PropTypes.string.isRequired,
};

export default BacklogChallengeBanner;