import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import HeaderSupplier from "./HeaderSupplier";
import LastPlayedInfo from "./LastPlayedInfo";
import { greaterOrEqualThan, lessOrEqualThan } from "../util/mediaQuery";

const GameDiv = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
`;
const GameInfoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-flow: row nowrap;
  ${lessOrEqualThan.sm`flex-flow: row wrap;`};
  align-items: center;
  justify-content: flex-start;
`;
const GameDivider = styled(Divider)`
  margin-top: 2.25rem;
`;
const TruncatedTypography = styled(Typography)`
  max-width: 18rem;
  ${greaterOrEqualThan.xs`max-width: 15rem`};
  ${greaterOrEqualThan.sm`max-width: 20rem`};
  /* --- grid breakpoints in parent causes these inconsistent widths --- */
  ${greaterOrEqualThan.md`max-width: 17rem`};
  ${greaterOrEqualThan.lg`max-width: 20rem`};
`;

const LastPlayed = ({ lastPlayed }) => (
  <>
    <Typography component="h2" variant="h6" color="inherit" gutterBottom>Last played</Typography>
    <GameDiv>
      <TruncatedTypography component="p" variant="subtitle1" color="secondary" gutterBottom noWrap>{lastPlayed[0].gameName}</TruncatedTypography>
      <GameInfoDiv>
        <HeaderSupplier />
        <LastPlayedInfo game={lastPlayed[0]} />
      </GameInfoDiv>
    </GameDiv>
    <GameDivider component="hr" />
    <GameDiv>
      <TruncatedTypography component="p" variant="subtitle1" color="secondary" gutterBottom noWrap>{lastPlayed[1].gameName}</TruncatedTypography>
      <GameInfoDiv>
        <HeaderSupplier />
        <LastPlayedInfo game={lastPlayed[1]} />
      </GameInfoDiv>
    </GameDiv>
    <GameDivider component="hr" />
    <GameDiv>
      <TruncatedTypography component="p" variant="subtitle1" color="secondary" gutterBottom noWrap>{lastPlayed[2].gameName}</TruncatedTypography>
      <GameInfoDiv>
        <HeaderSupplier />
        <LastPlayedInfo game={lastPlayed[2]} />
      </GameInfoDiv>
    </GameDiv>
  </>
);
LastPlayed.propTypes = {
  lastPlayed: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
    userRating: PropTypes.number.isRequired,
    playedHours: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};
export default LastPlayed;
