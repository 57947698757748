import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { pickRandomly } from "../util/utilityFunctions";

const HeaderContainer = styled.div`
  display: inline-block;
  min-width: 8rem;
  max-width: 13rem;
  flex-grow: 1;
`;

/**
 * Returns one random game-header.
 * Uses GatsbyImage and GraphQL to load images.
 * @see https://www.orangejellyfish.com/blog/a-comprehensive-guide-to-images-in-gatsby/
 */
const HeaderSupplier = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {
        extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
        sourceInstanceName: {eq: "game-headers"}}) 
      {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 150, quality: 100) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }`);
  const header = pickRandomly(allFile.edges);
  const { fluid } = header.node.childImageSharp;

  return (
    <HeaderContainer>
      <GatsbyImage fluid={fluid} alt={fluid.originalName} />
    </HeaderContainer>
  );
};

export default HeaderSupplier;
