import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import HeaderSupplier from "./HeaderSupplier";


const WhatToPlaySection = styled.div`
  margin-top: 2rem;
`;
const GamesAmountContainer = styled.div`
  display: flex;
  margin-right: 2rem;
  padding-right: 2rem;
  align-items: flex-end;
`;
const HeaderRowDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const PlayNext = ({ playNext, forBacklog }) => {
  if (forBacklog) {
    return (
      <>
        <Typography component="h2" variant="h6" color="inherit" gutterBottom>
          You should play these games next
        </Typography>

        <WhatToPlaySection>
          <HeaderRowDiv>
            <HeaderSupplier />
            <HeaderSupplier />
            <HeaderSupplier />
            <HeaderSupplier />
          </HeaderRowDiv>
          <GamesAmountContainer />
        </WhatToPlaySection>
      </>
    );
  }

  return (
    <>
      <Typography component="h2" variant="h6" color="inherit" gutterBottom>
        What to play next
      </Typography>

      <WhatToPlaySection>
        <HeaderRowDiv>
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
        </HeaderRowDiv>
        <GamesAmountContainer>
          <Typography variant="subtitle1" color="secondary">Want to play</Typography>
          <Typography variant="body1" color="inherit" style={{ marginLeft: "0.5rem" }}>
            {playNext.wantToPlay.amount} games
          </Typography>
        </GamesAmountContainer>
      </WhatToPlaySection>

      <WhatToPlaySection>
        <HeaderRowDiv>
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
        </HeaderRowDiv>
        <Typography variant="subtitle1" color="secondary">Similar to Transistor</Typography>
      </WhatToPlaySection>

      <WhatToPlaySection>
        <HeaderRowDiv>
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
        </HeaderRowDiv>
        <Typography variant="subtitle1" color="secondary">Currently Popular</Typography>
      </WhatToPlaySection>

      <WhatToPlaySection>
        <HeaderRowDiv>
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
          <HeaderSupplier />
        </HeaderRowDiv>
        <Typography variant="subtitle1" color="secondary">Recently Updated</Typography>
      </WhatToPlaySection>
    </>
  );
};
PlayNext.defaultProps = {
  forBacklog: false,
};
PlayNext.propTypes = {
  playNext: PropTypes.shape({
    wantToPlay: PropTypes.shape({
      headers: PropTypes.array.isRequired,
      amount: PropTypes.number.isRequired,
    }).isRequired,
    popular: PropTypes.shape({
      headers: PropTypes.array.isRequired,
    }).isRequired,
    similar: PropTypes.shape({
      headers: PropTypes.array.isRequired,
      similarGame: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  forBacklog: PropTypes.bool,
};

export default PlayNext;
