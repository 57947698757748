import React from "react";
import * as PropTypes from "prop-types";
import { Typography, List, ListItem, Divider } from "@material-ui/core";
import { GrowDiv } from "./AccountStats";

const Shelves = ({ shelves }) => {
  const { fixedShelves, customShelves } = shelves;
  return (
    <>
      <Typography component="h2" variant="h6" color="inherit" gutterBottom>My Shelves</Typography>

      <List component="ul" dense>
        {fixedShelves.map(element => (
          <ListItem component="li" key={element[0]} disableGutters>
            <Typography component="span" variant="body1" color="textSecondary">
              {element[0]}
            </Typography>
            <GrowDiv />
            <Typography component="span" variant="body1" color="textPrimary">
              {element[1]}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Divider component="hr" />
      <List component="ul" dense>
        {customShelves.map(element => (
          <ListItem component="li" key={element[0]} disableGutters>
            <Typography component="span" variant="body1" color="textSecondary">
              {element[0]}
            </Typography>
            <GrowDiv />
            <Typography component="span" variant="body1" color="textPrimary">
              {element[1]}
            </Typography>
          </ListItem>
        ))}
      </List>
    </>
  );
};
Shelves.propTypes = {
  shelves: PropTypes.shape({
    fixedShelves: PropTypes.arrayOf(
      PropTypes.any.isRequired,
    ).isRequired,
    customShelves: PropTypes.arrayOf(
      PropTypes.any.isRequired,
    ).isRequired,
  }).isRequired,
};

export default Shelves;