import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import OoPieChart from "../element/chart/OoPieChart";
import OoPieChartLegend from "../element/chart/OoPieChartLegend";
import { OoChartGridContainer, OoChartGridItem } from "../element/chart/OoChartGrid";

const COLORS = ["#3c00be", "#0bdff2", "#f15a24", "#ffdd00", "#50dc4b", "#00bfa5"];

class Charts extends React.PureComponent {
  constructor(props) {
    super(props);
    const { accountCharts } = this.props;
    const { playtimeChart, ownedGamesChart } = accountCharts;

    const playtimeChartWithColor = playtimeChart.map((element, index) => (
      { ...element, color: COLORS[index] }));
    const ownedGamesChartWithColor = ownedGamesChart.map((element, index) => (
      { ...element, color: COLORS[index] }));

    this.state = {
      playtimeChart: playtimeChartWithColor,
      ownedGamesChart: ownedGamesChartWithColor,
    };
  }

  render() {
    const { playtimeChart, ownedGamesChart } = this.state;
    return (
      <OoChartGridContainer>
        <OoChartGridItem>
          <Typography component="h2" variant="h6" align="center">
            Top tags by playtime
          </Typography>
          <OoPieChart colors={COLORS} data={playtimeChart} />
          <OoPieChartLegend data={playtimeChart} />
        </OoChartGridItem>
        <OoChartGridItem>
          <Typography component="h2" variant="h6" align="center">
            Top tags by owned games
          </Typography>
          <OoPieChart colors={COLORS} data={ownedGamesChart} />
          <OoPieChartLegend data={ownedGamesChart} />
        </OoChartGridItem>
      </OoChartGridContainer>

    );
  }
}
Charts.propTypes = {
  accountCharts: PropTypes.shape({
    playtimeChart: PropTypes.array.isRequired,
    ownedGamesChart: PropTypes.array.isRequired,
    playingWeek: PropTypes.array.isRequired,
  }).isRequired,
};
export default Charts;