export const data = {
  playNext: {
    wantToPlay: {
      headers: [1, 2, 3, 4],
      amount: 96,
    },
    popular: {
      headers: [1, 2, 3, 4],
    },
    similar: {
      headers: [1, 2, 3, 4],
      similarGame: "Transistor",
    },
  },
  lastPlayed: [
    {
      header: "",
      gameName: "Factorio",
      userRating: 4,
      playedHours: 22,
    },
    {
      header: "",
      gameName: "Thomas was alone",
      userRating: 0,
      playedHours: 2,
    },
    {
      header: "",
      gameName: "Tales of Monkey Island Complete Pack: Chapter 4 - The Trial and Execution of Guybrush Threepwood",
      userRating: 5,
      playedHours: 1140,
    },
  ],
  backlogChallenge: {
    year: 2019,
    completed: 22,
    goal: 40,
  },
  accountStats: {
    ownedGames: 100,
    playedGames: 50,
    neverPlayedGames: 50,
    wantToPlay: 100,
    totalGamingHours: 2000,
    avgGamingHours: 20,
    medianGamingHours: 2,
    perfectAchievements: 10,
  },
  accountCharts: {
    playtimeChart: [
      { tag: "SCI-FI", value: 400 },
      { tag: "FANTASY", value: 350 },
      { tag: "CYBERPUNK", value: 300 },
      { tag: "STRATEGY", value: 250 },
      { tag: "STORY RICH", value: 100 },
      { tag: "ADVENTURE", value: 50 },
    ],
    ownedGamesChart: [
      { tag: "FANTASY", value: 400 },
      { tag: "SCI-FI", value: 200 },
      { tag: "CYBERPUNK", value: 180 },
      { tag: "CASUAL", value: 150 },
      { tag: "STORY RICH", value: 50 },
      { tag: "ADVENTURE", value: 40 },
    ],
    playingWeek: [],
  },
  shelves: {
    fixedShelves: [
      ["Want to Play", 96],
      ["Playing", 6],
      ["Played", 42],
      ["Liked", 31],
      ["Ignored", 8],
      ["Abandoned", 5],
    ],
    customShelves: [
      ["Indie Gems", 64],
      ["I love cats", 4],
      ["Games when kids are around", 12],
      ["Beautiful colors", 12],
      ["I MUST DESTROY", 17],
      ["So good I cried", 9],
    ],
  },
  badges: [
    {
      badgeId: 1,
      description: "Yearling",
      icon: "",
    },
    {
      badgeId: 2,
      description: "I occasionally play video games",
      icon: "",
    },
    {
      badgeId: 3,
      description: "First Discovery Tour",
      icon: "",
    },
    {
      badgeId: 4,
      description: "Connected Steam account",
      icon: "",
    },
  ],
  genres: [
    ["Indie", "Strategy"],
    ["Indie", "2D"],
    ["Action", "RPG"],
    ["Action", "FPS"],
    ["Action", "Jump'n'run"],
    ["Adventure"],
    ["Strategy"],
    ["Sports"],
    ["Sports", "Dogs"],
    ["RPG"],
  ],
};