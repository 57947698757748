import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const breadcrumb = ">";

const Genres = ({ genres }) => (
  <>
    <Typography component="h2" variant="h6" color="inherit" gutterBottom>
      Favorite Genres
    </Typography>

    <List component="ul" dense>
      {genres.map((element, index) => (
        <ListItem component="li" key={element[0] + index} disableGutters>
          <Typography component="span" variant="body1" color="textSecondary">
            {element[0]}
          </Typography>

          {(typeof element[1] !== "undefined")
          && (
            <>
              <Typography component="span" variant="body1" color="textPrimary">
                &nbsp;{breadcrumb}&nbsp;
              </Typography>
              <Typography component="span" variant="body1" color="textSecondary">
                {element[1]}
              </Typography>
            </>
          )}
        </ListItem>
      ))}
    </List>
  </>
);
Genres.propTypes = {
  genres: PropTypes.arrayOf(
    PropTypes.any.isRequired,
  ).isRequired,
};

export default Genres;