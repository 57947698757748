import React from "react";
import * as PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import List from "@material-ui/core/List";

const Dot = styled.span`
  height: 0.9rem;
  width: 0.9rem;
  margin-right: 0.6rem;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
`;
const ChartLegendDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
`;
const TruncatedList = styled(List)`
  width: 50%;
`;

const OoPieChartLegend = ({ data }) => (
    <ChartLegendDiv>
      <TruncatedList component="ul" dense>
        {data.slice(0, 3).map(element => (
          <ListItem component="li" key={element.tag}>
            <Typography component="span" variant="caption" noWrap>
              <Dot color={element.color} />
              {element.tag}
            </Typography>
          </ListItem>
        ))}
      </TruncatedList>
      <TruncatedList component="ul" dense>
        {data.slice(3, 6).map(element => (
          <ListItem component="li" key={element.tag}>
            <Typography component="span" variant="caption" noWrap>
              <Dot color={element.color} />
              {element.tag}
            </Typography>
          </ListItem>
        ))}
      </TruncatedList>
    </ChartLegendDiv>
);
OoPieChartLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};
export default OoPieChartLegend;