import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Layout from "../components/element/layout/Layout";
import theme from "../components/element/theme";
import OoPaper from "../components/element/page-element/OoPaper";
import PageBanner from "../components/element/page-element/PageBanner";
import NewsletterBanner from "../components/navigation/footer/NewsletterBanner";
import { ROUTES } from "../components/navigation/InternalRoute";
import LastPlayed from "../components/library/LastPlayed";
import Badges from "../components/library/badge/Badges";
import PlayNext from "../components/library/PlayNext";
import SEO from "../components/element/SEO";
import { data } from "../components/library/librarySampleData";
import BacklogChallengeBanner from "../components/library/BacklogChallengeBanner";
import { DiscoveryTourExplore } from "../components/library/HeroBanners";
import AccountStats from "../components/library/AccountStats";
import Charts from "../components/library/Charts";
import LibraryCta from "../components/library/LibraryCta";
import Shelves from "../components/library/Shelves";
import Genres from "../components/library/Genres";
import { ContentDiv, MainGrid, SideGrid, defaultPaper, defaultPaperNoBackground, illustrationPaper } from "../components/element/layout/ContentContainers";


const illustrationHeight = "15.27rem";

const h1Heading = "Your Gaming Library";
const h1Subheading = "The links in this preview don't work yet. Sorry!";
const newsletterMessage = "Get a mail when this is fully ready for your own Steam library!";
const previewNotice = (
  <Typography variant="h2" color="secondary" gutterBottom>PREVIEW</Typography>);

const seo = {
  title: "Gaming Library Preview - Oberion",
  description: "Analyze your gaming library. Find out more about what you play and how you play it.",
};

const LibraryPreview = () => (
  <Layout>
    <SEO {...seo} />
    <PageBanner heading={h1Heading} subheading={h1Subheading} minHeight="15rem"
                extraComponent={previewNotice} />
    <NewsletterBanner padding="2rem 0.5rem" path={ROUTES.LIBRARY.TO}
                      message={newsletterMessage} />

    <ContentDiv>
      <Grid container spacing={3}>
        <MainGrid item sx={12} md={8}>

          <OoPaper {...defaultPaper}>
            <PlayNext playNext={data.playNext} />
          </OoPaper>

          <OoPaper {...defaultPaperNoBackground}>
            <AccountStats accountStats={data.accountStats} />
          </OoPaper>

          <OoPaper {...defaultPaperNoBackground}>
            <Charts accountCharts={data.accountCharts} />
          </OoPaper>

          <OoPaper {...defaultPaper}>
            <Badges />
          </OoPaper>

          <OoPaper {...illustrationPaper}>
            <LibraryCta illustrationHeight={illustrationHeight} />
          </OoPaper>

        </MainGrid>
        <SideGrid item sx={12} md={4}>

          <OoPaper {...defaultPaper}>
            <LastPlayed lastPlayed={data.lastPlayed} />
          </OoPaper>

          <OoPaper {...illustrationPaper}>
            <BacklogChallengeBanner backlogChallenge={data.backlogChallenge}
                              illustrationHeight={illustrationHeight} />
          </OoPaper>

          <OoPaper {...illustrationPaper}>
            <DiscoveryTourExplore illustrationHeight={illustrationHeight} />
          </OoPaper>

          <OoPaper {...defaultPaper}>
            <Shelves shelves={data.shelves} />
          </OoPaper>

          <OoPaper {...defaultPaper}>
            <Genres genres={data.genres} />
          </OoPaper>

        </SideGrid>
      </Grid>
    </ContentDiv>
  </Layout>
);

export default LibraryPreview;
